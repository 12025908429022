<template>
  <div id="app">
    <div class="hinda-booking-wrap">
      <router-view/>
    </div>
    <app-loader v-if="$store.getters.showLoader"></app-loader>
  </div>
</template>
<script>
import AppLoader from './components/AppLoader.vue'
import autoGrow from './mixins/autogrow'
export default {
  components: { AppLoader },
  mixins: [autoGrow],
  mounted () {
    const self = this
    window.addEventListener('message', function (event) {
      // Checking origin of the event
      if (event.origin !== self.$store.getters.domain) {
        return
      }
      if (event.data.event) {
        if (event.data.event === 'p2mResize') {
          self.sendResizeContent(false, false)
        } else if (event.data.event === 'initialize') {
          self.$store.dispatch('setIframeId', event.data.iFrameId)
          self.sendResizeContent(true, true)
        }
      }
    })
  }
}
</script>
<style lang="scss">
  @import "./assets/index.scss";
  // min-height for dialog
  #app {
    background: #F1F2F2;
    padding: 58px 72px;
    min-height: calc(724px - 116px);
  }
  .hinda-booking-wrap {
    background: #fff;
    padding: 40px;
    max-width: 1056px;
    margin: 0 auto;
  }
</style>
